import React from 'react';

/**
 * Danke Nico für den genialen Namen den du dieser Komponente gabst
 * Danke Dennis für die globale Einführung des umgangssprachlichen Präteritums
 */
const campaignLogo = (props) => props.linkUrl ? <a className="anl_logo--customer" href={props.linkUrl} target="_blank" rel="external noopener noreferrer"
                                                   title={`Zu ${new URL(process.env.REACT_APP_CUSTOMER_URL).hostname} wechseln`}><img src={props.src} alt="" className="img-fluid" /></a> : <img src={props.src} alt="" className="img-fluid" />;

export default campaignLogo;
